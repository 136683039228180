<template>
  <div class="tc">
    <input
      class="font16"
      v-for="(item, index) in length"
      v-model.number="code[index]"
      onkeyup="value=value.replace(/[^\d]/g,'')"
      type="text"
      maxlength="1"
      @input="inputFun($event, index)"
      :key="index"
    />
  </div>
</template>
<script>
export default {
  name: "smsCode",
  props: {
    value: {
      type: String,
      default: "",
    },
    length: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      code: this.value.split(""),
    };
  },
  watch: {
    value(newVal) {
      this.code = newVal.split("");
    },
    code(newVal) {
      this.$emit("input", newVal.join(""));
    },
  },
  methods: {
    //1.监听用的键入，每次键入都替换掉焦点输入框的值
    //2.输入框的值只能有一位
    inputFun(e, index) {
      let inputOver = false;
      let ele = e.target;
      let siblingsNode = ele.parentNode.children;
      if (!e.data) {
        //删除
        // this.$delete(this.code,p);
        this.code[index] = "";
        if (index <= 0) {
          //边界值
          index = 0;
          siblingsNode[index].focus();
        } else {
          siblingsNode[index - 1].focus();
        }
        return false;
      }
      let value = e.target.value.replace(/\W/g, "").slice(-1);
      ele.value = value;
      this.$set(this.code, index, value);
      if (index >= siblingsNode.length - 1) {
        //边界值
        index = siblingsNode.length - 1;
      } else {
        //上一个元素获得焦点
        siblingsNode[index + 1].focus();
      }
      for (let i = 0; i < this.length; i++) {
        if (this.code[i] == undefined) {
          inputOver = false;
          break;
        } else {
          inputOver = true;
        }
      }
      if (inputOver) {
        this.$emit("ok", this.code);
      }
    },
  },
};
</script>
<style lang="less" scoped>
input {
  width: 45px;
  margin-right: 16px;
  height: 60px;
  border: none;
  border-bottom: 1px solid #f1d8d8;
  text-align: center;
  background: transparent;
  color: #fff;
  &:focus {
    outline: none;
    border-bottom: 1px solid rgba(255, 208, 209, 1);
  }
}
</style>
