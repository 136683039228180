import utils from "@/utils/utils";
import _ from "lodash";
import axios from "./axios";
import components from "./components";
import SlideLogin from "@/components/SlideLogin"; //滑动登录jq封装
// import Verify from "@/components/verifition/Verify";
import requests from "./requests";

export default {
  install(Vue) {
    _.extend(Vue.prototype, {
      $utils: utils,
      $axios: axios,
      $requests: requests,
    });

    for (let name in components) {
      Vue.component(name, components[name]);
    }
    Vue.component("SlideLogin", SlideLogin);
    // Vue.component("Verify", Verify);
  },
};
