<template>
  <!-- 
	测试地址：http://www.cmcqs.com/captcha/test
	线上地址：http://www.cmcqs.com/captcha
 -->
  <!-- H5滑动滚动 -->
  <div class="slide-login">
    <div class="relative wrapper">
      <div style="color:#999;margin-bottom:10px;">请完成拼图验证</div>
      <div id="mpanel1"></div>
      <div class="close"></div>
    </div>
  </div>
</template>

<script>
import "./js/verify.js";
const memberApi = process.env.VUE_APP_API_MEMBER; //客户端
export default {
  name: "layout",
  props: {
    h5: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    let vue = this;
    let userAgent = navigator.userAgent;
    let isAndroid =
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1; //g
    let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid && !vue.h5) {
      $(".close").hide();
    }
    // 初始化验证码  嵌入式
    $("#mpanel1").slideVerify({
      // baseUrl: 'http://localhost:8001', //服务器请求地址, 默认地址为安吉服务器;
      baseUrl: memberApi, //服务器请求地址, 默认地址为安吉服务器;
      mode: "fixed",
      imgSize: {
        //图片的大小对象
        width: "300px",
        height: "200px",
      },
      barSize: {
        width: "100%",
        height: "36px",
      },
      ready: function() {
        //加载完毕的回调
      },
      success: function(params) {
        //成功的回调
        // 返回的二次验证参数 合并到验证通过之后的逻辑 参数中回传服务器
        if (vue.h5) {
          vue.$emit("ok", params);
          return;
        }
        console.log(
          "params===",
          params,
          "isAndroid:",
          isAndroid,
          "isIOS:",
          isIOS,
          "window.webkit:::",
          window.webkit
        );
        if (isAndroid) {
          //这个是安卓操作系统
          window.AndroidJs.getSlideData(JSON.stringify(params));
        }
        if (isIOS) {
          //这个是ios操作系统
          try {
            window.webkit.messageHandlers.getSlideData.postMessage(params);
          } catch (err) {
            console.log(
              "error:::执行window.webkit.messageHandlers.getSlideData.postMessage(params)不成功"
            );
          }
        }
      },
      error: function() {
        //失败的回调
      },
    });
    $(".close").click(function() {
      if (vue.h5) {
        vue.$emit("close");
        return;
      }
      console.log("closing", window.webkit);
      if (isIOS) {
        try {
          window.webkit.messageHandlers.getCloseData.postMessage();
        } catch (err) {
          console.log(
            "error:::执行window.webkit.messageHandlers.closeWebview.postMessage()不成功"
          );
        }
      }
    });
  },
};
</script>

<style lang="less" scoped>
@import "./css/verify.css";
</style>
