import axios from "axios";
import qs from "qs";
import router, { routes } from "@/router/index.js";
import techRequests from "@/requests/tech";
import memberRequests from "@/requests/member";
import adminRequests from "@/requests/admin";

// const api = "https://tech.cmcqs.cn"; //测试
// const api = "https://tech.cmcqs.com"; //正式
const techApi = process.env.VUE_APP_API_TECH; //技师端
const memberApi = process.env.VUE_APP_API_MEMBER; //客户端
const adminApi = process.env.VUE_APP_API_ADMIN; //admin后台

const techRequestsList = Object.values(techRequests);
const memberRequestsList = Object.values(memberRequests);
const adminRequestsList = Object.values(adminRequests);

// 添加aixo拦截器
axios.interceptors.request.use(
  function(config) {
    if (techRequestsList.indexOf(config.url) != -1) {
      config.url = techApi + config.url;
    } else if (memberRequestsList.indexOf(config.url) != -1) {
      config.url = memberApi + config.url;
    } else if (adminRequestsList.indexOf(config.url) != -1) {
      config.url = adminApi + config.url;
    }
    if (config.method.toLowerCase() == "post") {
      config.data = qs.stringify(config.data);
    }
    console.log(config,config.url)
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default axios;
