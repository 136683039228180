export default {
  getFullSubtractBaseInfo: "/zoneProject/baseInfo", //折扣专区=>基本信息
  getFullSubtractList: "/zoneProject/discountCutProjectPage", //折扣专区=>项目列表
  getDiscountBaseInfo: "/zoneProject/baseInfo", //折扣专区=>基本信息
  getDiscountList: "/zoneProject/discountCutProjectPage", //折扣专区=>项目列表
  getExcellentTechCateLogList: "/zoneGoodTech/goodTechCatalog", //优选技师=>类目
  getExcellentTechList: "/zoneGoodTech/goodTechPage", //优选技师=>列表
  getNewRecommendCateLogList: "/zoneNewProject/newProjectCatalog", //新品推荐=>类目
  getNewRecommendList: "/zoneGoodTech/newProjectPage", //新品推荐=>列表
  getNewActiveDataList: "/getActiveData", //舒昕逸家新版本活动=>基本信息
  getNewActiveSalesList: "/getServiceSalesPromotion", //舒昕逸家新版本活动=>推荐列表数据
  getWeddingGiftPackage: "/getWeddingGiftPackage", //舒昕逸家新版本活动=>新人大礼包优惠券数据列
  getInvitationPolite: "/queryInvitationPolite", //舒昕逸家邀请有礼活动=>我的邀请记录
  getSubMember: "/getSubMember",//舒昕逸家 站长管理 获取站长旗下 会员列表

  getRegistrationTotal: "/registrationActivity/total",//团购登记总人数
  addRegistration: "/registrationActivity/add",//团购登记
};
